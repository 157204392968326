import axios from 'axios'

const baseUrl =
  process.env.GATSBY_ENV === 'production'
    ? 'https://hopeonesource-api.herokuapp.com/api/aws/s3-url'
    : 'https://hopeonesource-api-dev.herokuapp.com/api/aws/s3-url'

// Generate presigned url for viewing or uploading s3 file
export async function getPresignedUrl(providerId, filename, operation, type) {
  try {
    const params = new URLSearchParams({
      providerId,
      filename,
      operation,
      type
    }).toString()
    const url = `${baseUrl}?${params}`

    const response = await axios.get(url)

    return response.data.url
  } catch (error) {
    console.error('Error fetching S3 URL:', error)
    throw error
  }
}

// Upload a file to S3
export const uploadToS3 = async (file, providerId, filename) => {
  const uploadURL = await getPresignedUrl(
    providerId,
    filename,
    'upload',
    file.type
  )
  const config = {
    headers: {
      'Content-Type': file.type
    }
  }

  try {
    await axios.put(uploadURL, file, config)
    return
  } catch (error) {
    console.error('Error uploading file to S3:', error)
    throw error
  }
}
