import React, { useEffect, useContext, useState } from 'react'

import { useTheme, styled } from '@material-ui/core/styles'
import useMediaQuery from '@material-ui/core/useMediaQuery'
import { Box, Button, CircularProgress, makeStyles } from '@material-ui/core'
import { Alert } from '@material-ui/lab'
import ArrowForwardIcon from '@material-ui/icons/ArrowForward'
import ArrowBackIcon from '@material-ui/icons/ArrowBack'

import CheckInFormField from './CheckInFormField'
import CheckInThankYou from './CheckInThankYou'
import { GlobalContext } from '../../context/GlobalContext'

import { getUserByID, saveUser, saveCheckin } from '../../functions/database'
import { uploadToS3 } from '../../functions/s3'
import { checkinErrors } from './CheckinErrors'
import defaultQuestionsLibrary from '../../constants/defaultQuestionsLibrary'
import { translateProviderForm } from '../../functions/translation'
import {
  isAnswered,
  generateContactID,
  filterOneTimeQuestions,
  containsOneTimeQuestions
} from '../../functions/utils'

import { v4 as uuidv4 } from 'uuid'

const useStyles = makeStyles((theme) => ({
  question: {
    textAlign: 'center',
    display: 'flex',
    flexDirection: 'column',
    alignItems: 'center',
    justifyContent: 'center',
    padding: '1rem',
    border: '2px solid #ccc',
    borderRadius: '5px',
    backgroundColor: '#f2efef',
    marginBottom: '1rem',
    width: '100%'
  },
  navButtons: {
    width: '100%',
    display: 'flex',
    justifyContent: 'space-around',
    alignItems: 'center',
    padding: '2vh',

    [theme.breakpoints.down('sm')]: {
      justifyContent: 'space-between'
    }
  },
  submitButton: {
    width: '100%',
    display: 'flex',
    justifyContent: 'center',
    alignItems: 'center',
    paddingTop: '3vh'
  }
}))

const CheckinFormWrapper = styled(Box)(({ theme }) => ({
  display: 'flex',
  flexDirection: 'column',
  justifyContent: 'center',
  alignItems: 'center',
  [theme.breakpoints.only('xs')]: {
    width: '100vw',
    padding: '0 3vw'
  },
  [theme.breakpoints.up('sm')]: {
    width: '80vw'
  },
  [theme.breakpoints.up('md')]: {
    width: '60vw'
  },
  [theme.breakpoints.up('lg')]: {
    width: '40vw'
  },
  [theme.breakpoints.up('xl')]: {
    width: '30vw'
  }
}))

export default function CheckInForm(props) {
  const theme = useTheme()
  const classes = useStyles()
  const {
    isBrowser,
    loading,
    setLoading,
    language,
    providerID,
    db,
    translator,
    providerForm,
    filteredProviderForm,
    setFilteredProviderForm,
    userID,
    setUserID,
    newUser,
    setNewUser,
    userData,
    setUserData,
    answers,
    setAnswers,
    currentLanguage,
    setCurrentLanguage,
    alertMessage,
    setAlertMessage,
    error,
    setError,
    currentIndex,
    setCurrentIndex,
    submissionLoading,
    setSubmissionLoading,
    submitted,
    setSubmitted
  } = useContext(GlobalContext)

  const { previewMode } = props

  //Identify repeat visitors
  useEffect(() => {
    const lastNameID = defaultQuestionsLibrary.idQuestions[0].questionID
    const dobID = defaultQuestionsLibrary.idQuestions[1].questionID

    async function findUser() {
      setLoading(true)

      //Generate user ID and attempt to find
      //answers is an object with each sub-object being having the shape questionID: {...question info}
      const userID = generateContactID(
        answers[lastNameID].value,
        answers[dobID].value,
        providerID
      )
      setUserID(userID)

      //Find user and filter form if they are a repeat visitor
      const user = await getUserByID(db, userID)

      if (user) {
        setNewUser(false)
        const filteredForm = filterOneTimeQuestions(providerForm)
        setFilteredProviderForm(filteredForm)
      }

      setLoading(false)
    }

    //if question #2 is dob then attempt to lookup user at question #3
    if (db && currentIndex === 2 && answers[lastNameID] && !userID) {
      findUser()
    }
  }, [currentIndex, db])

  // update the form when the language changes
  useEffect(() => {
    if (currentLanguage !== language) {
      async function translate() {
        const translatedForm = await translateProviderForm(
          translator,
          filteredProviderForm,
          language
        )
        setFilteredProviderForm(translatedForm)
      }
      translate()
      setCurrentLanguage(language)
    }
  }, [language])

  // Check for forward navigation attempt and if current question is required and unanswered
  const onPageChange = (newIndex) => {
    const currentQuestion = filteredProviderForm[currentIndex]

    if (
      newIndex > currentIndex &&
      currentQuestion.isRequired &&
      !isAnswered(answers, currentQuestion.questionID)
    ) {
      setAlertMessage('Please answer this question before continuing.')
    } else {
      // Reset alert message on successful navigation
      setAlertMessage('')
      setCurrentIndex(newIndex)
    }
  }

  //Triggered when CheckinFormField objects are modified to save user input
  const onFieldChange = (data) => {
    //Store answers to one-time questions in userData object to be saved in Contacts database
    if (
      filteredProviderForm[currentIndex].frequency === 'firstTime' &&
      newUser
    ) {
      const updatedUserData = { ...userData, [data.id]: data }
      setUserData(updatedUserData)
    }

    // Clear the alert message when a field is answered
    setAlertMessage('')

    const updatedAnswers = { ...answers, [data.id]: data }
    setAnswers(updatedAnswers)
  }

  //Extract any images from the answers object and upload to S3
  const extractImages = async (answers) => {
    const updatedAnswers = { ...answers }
    try {
      const uploadPromises = Object.entries(updatedAnswers).map(
        async ([answerID, answer]) => {
          if (answer.fieldType === 'photo upload') {
            const filename = uuidv4()
            await uploadToS3(answer.value, providerID, filename)
            updatedAnswers[answerID] = { ...answer, value: filename }
          }
        }
      )
      await Promise.all(uploadPromises)
    } catch (error) {
      console.error('Image Extraction Error', error)
      throw error
    }
    return updatedAnswers
  }

  //Perform pre-submit operations and save responses to db
  const onSubmit = async () => {
    setSubmissionLoading(true)

    // Check if all required questions are answered before submitting
    const isFormCompleted = filteredProviderForm.every(
      (question) =>
        !question.isRequired || isAnswered(answers, question.questionID)
    )

    if (!isFormCompleted) {
      setAlertMessage('Please answer all required questions before submitting.')
      return
    }

    try {
      const updatedAnswers = await extractImages(answers)

      if (newUser && containsOneTimeQuestions(filteredProviderForm)) {
        await saveUser(db, userID, userData)
      }

      await saveCheckin(db, providerID, updatedAnswers)

      setSubmitted(true)
    } catch (error) {
      console.error('Failed to submit check-in form', error)
      setError(checkinErrors.submissionError)
      alert('Error Submitting Form Response')
    } finally {
      setSubmissionLoading(false)
    }
  }

  if (loading || !providerForm) {
    return (
      <CheckinFormWrapper>
        <CircularProgress />
      </CheckinFormWrapper>
    )
  }

  return (
    <CheckinFormWrapper>
      {alertMessage && <Alert severity='error'>{alertMessage}</Alert>}
      {error && <Alert severity='error'>{error}</Alert>}
      {submitted ? (
        <CheckInThankYou
          providerName={providerForm.providerName}
          handleReturn={() => isBrowser() && window.location.reload()}
        />
      ) : (
        <Box className={classes.question}>
          <CheckInFormField
            key={filteredProviderForm[currentIndex].questionID}
            question={filteredProviderForm[currentIndex]}
            answer={answers[filteredProviderForm[currentIndex].questionID]}
            onFieldChange={onFieldChange}
            id={filteredProviderForm[currentIndex].questionID}
          />
        </Box>
      )}

      {currentIndex === filteredProviderForm.length - 1 && !submitted && (
        <Box className={classes.submitButton}>
          <Button
            variant='contained'
            color='primary'
            onClick={onSubmit}
            disabled={submissionLoading || submitted}
          >
            Submit
          </Button>
        </Box>
      )}

      {!submitted && (
        <Box className={classes.navButtons}>
          <Button
            color='primary'
            variant='contained'
            disabled={currentIndex === 0 || submitted}
            onClick={() => onPageChange(currentIndex - 1)}
          >
            <ArrowBackIcon />
          </Button>
          <Button
            color='primary'
            variant='contained'
            disabled={
              currentIndex === filteredProviderForm.length - 1 || submitted
            }
            onClick={() => onPageChange(currentIndex + 1)}
          >
            <ArrowForwardIcon />
          </Button>
        </Box>
      )}
    </CheckinFormWrapper>
  )
}
